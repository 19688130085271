.slider {
    position: sticky;
    width: 750px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 700px;
}

.image {
    width: 45%;
    border-radius: 10px;
    cursor: pointer;
}
.slide {
    transition-duration: .5s ease-in-out;
}

.slide.active {
    transition-duration: 3s;
}
@media (min-width: 1600px) {
    .slider {
        margin-left: 0%;
        padding-right: 40%;
    }
    .image {
        width: 75%;
    }
}
@media (max-width: 1599px) {
    .slider {
        margin-left: 35%;
        /* padding-right: 23%; */
    }
    .image {
        width: 45%;
    }
}


@media (min-width: 1100px) {
    .slider {
        margin-left: 30%;
    }
}
@media (min-width: 768px) {
    .slider {
        margin-left: 33%;
    }
}
@media (min-width: 1500px) {
    .slider {
        margin-left: 10%;
        width: 130%;
    }
}