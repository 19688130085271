
.name-text
{
    font-size: 2.25rem/* 36px */;
    line-height: 2.5rem/* 40px */;
    margin-right: auto;
}

.pagename-text
{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    border-radius: 0.5rem/* 8px */;
    font-weight: 500;
    cursor: pointer;
    transition-duration: 200ms;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
}

.pagename-text:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity));
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}




@media (min-width: 1650px)
{
    .name-text
    {
        font-size: 3rem/* 36px */;
        line-height: 3.7rem/* 40px */;
    }
    .pagename-text
    {
        font-size: 1.3rem/* 36px */;
        line-height: 2.6rem/* 40px */;
    }
}
/* 

@media (min-width: 1024px)
{
    .name-text
    {
        font-size: 3.7rem;
        line-height: 4rem;
    }
    .pagename-text
    {
        font-size: 1.6rem;
        line-height: 2.9rem;
    }
} */

