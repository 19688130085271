.pfp {
    display: flex;
    width: 130%;
    opacity: 1;
    height: auto;
    border-radius: 10px;
}

.img-contain
{
    position: sticky;
    top: 5%;
    left: 37%;
    width: 25%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {
    .img-contain {
        left: 30%;
        width: 35%;
    }
}

@media (max-width: 768px) {
    .img-contain {
        left: 30%;
        width: 40%;
    }
}

@media (max-width: 640px) {
    .img-contain {
        left: 30%;
        width: 45%;
    }
}