.info-container
{
    display: flex;
    width: 100%;
    padding: 10%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.galbutton
{
    position: absolute;
    left: 45%;
    bottom: -95%;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    background-color: white;
    justify-content: flex-end;
    padding: .75rem;
    border-radius: 0.375rem;
    width: fit-content;
    height: fit-content;
    border: black 1px solid;

}

.right-arrow {
    position: absolute;
    bottom: -95%;
    left: 60%;
    font-size: 3rem;
    color: black;
    background-color: white;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    border: black 1px solid;
    padding: .75rem;
    border-radius: 5px;
}

.left-arrow {
    position: absolute;
    bottom: -95%;
    left: 35%;
    font-size: 3rem;
    color: black;
    background-color: white;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    border: black 1px solid;
    padding: .75rem;
    border-radius: 5px;
}

.about-text
{
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    display: inline-block;
    white-space: normal;
}

@media(min-width: 1650px)
{
    .about-text
    {
        font-size: 1.5rem ;
        line-height: 2rem;
    }
}



@media (max-width: 1200px)
{
.left-arrow
{
    left: 35%;
}
.right-arrow
{
    left: 62%;
}
}

@media (max-width: 1024px)
{
.left-arrow
{
    left: 35%;
}
.right-arrow
{
    left: 65%;
}
}
@media (max-width: 1000px) {
.galbutton, .left-arrow, .right-arrow {
    display:none;
}
}
