body {
    --black: #000000;
    --white: #fafafa;
  
    --background: var(--white);
    --accent: var(--black);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
  }

  #progress {
    position: relative;
    transform: rotate(-90deg);
  }
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 7%;
    fill: none;
  }
  
  .bg {
    stroke: var(--white);
    opacity: 0.3;
  }
  
  #progress .indicator {
    stroke: var(--accent);
    transition: ease-in-out 400ms;
  }