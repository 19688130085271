.connect-button
{
    position: relative;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    background-color: white;
    justify-content: flex-end;
    border-radius: 0.375rem;
    width: fit-content;
    height: fit-content;
    border: slategray 1px solid;
    padding-top: 0.75rem/* 12px */;
    padding-bottom: 0.75rem/* 12px */;
    padding-left: 1.5rem/* 24px */;
    padding-right: 1.5rem/* 24px */;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    transition-duration: 300ms;
}

.connect-button:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.name-box
{
    padding: .5rem;
    background: transparent;
    border-radius: 0.375rem;
    border-width: 2px;
    --tw-text-opacity:1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: 500px;
    border: slategray 1px solid;
}

.contacts-container
{
    display: flex;
    flex-direction: row;
    margin-top: 7%;
}

@media (min-width: 1400px)
{
    .name-box
    {
        width: 800px;
    }
}

@media (max-width: 640px)
{
    .name-box
    {
        width: 300px;
    }
}