.whole
{
    width: 100%;
    display: flex;
    min-height: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 7%;
    margin-right: 7%;
    justify-content: center;
    flex-direction: row;
    flex: 1;

}

.left
{
    width: 50%;
    float: left;
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}
.right
{
    width: 50%;
    display: flex;
    float: right;
    justify-content: center;
}


.left1
{
    width: 50%;
    float: right;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10%;
}

.right1
{
    width: 50%;
    display: flex;
    float: left;
    justify-content: center;
}


.img
{
    width: 70%;
}

#macadjust
{
    width: 100%;
}

#mac-text
{
    margin-top: 10%;
}

@media (min-width: 1700px) {
    .img
    {
        width: 65%;
    }
    .left1
    {
        font-size: 1.7rem;
    }

}

@media (min-width: 1400px) {
    .img
    {
        width: 75%;
    }
    .left1
    {
        font-size: 1.2rem;
    }

}
@media (max-width: 1024px) {
    .img
    {
        width: 80%;
    }
    .left1
    {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .img
    {
        width: 100%;
    }
}
@media (max-width: 640px) {
    .img
    {
        width: fit-content;
        margin-bottom: 5%;
    }
    .left1, .right1
    {
        width: 90%;
    }
    .whole
    {
        flex:auto;
        flex-direction: column;
        overflow: hidden;
    }
    .left,.right
    {
        width: 100%;
    }
}